/*
* ===============================
*  START: Theme Default CSS
* ===============================
*/
body.light-mode {
	background: var(--tj-white);
	color: var(--tj-theme-accent-2);
}
.light-mode h1,
.light-mode h2,
.light-mode h3,
.light-mode h4,
.light-mode h5,
.light-mode h6 {
	color: var(--tj-theme-secondary);
}
.light-mode .btn:hover {
	color: var(--tj-white);
}
.light-mode .section-header .section-title {
	background: var(--tj-theme-primary);
	background: -webkit-gradient(
		linear,
		left top,
		right top,
		from(var(--tj-theme-primary)),
		to(var(--tj-theme-secondary))
	);
	background: -o-linear-gradient(
		left,
		var(--tj-theme-primary) 0%,
		var(--tj-theme-secondary) 100%
	);
	background: linear-gradient(
		to right,
		var(--tj-theme-primary) 0%,
		var(--tj-theme-secondary) 100%
	);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
.light-mode .owl-dots .owl-dot span {
	opacity: 1;
}
/* END: Theme Default CSS */

/*
* ===============================
* START: Headers
* ===============================
*/
.light-mode .tj-header-area.header-2 {
	background-color: var(--tj-off-white);
}
.light-mode .tj-header-area .menu-bar button span {
	background: var(--tj-theme-primary);
}
/* END: Headers */

/*
* ===============================
*  START: Hero Section
* ===============================
*/
.light-mode .hero-section {
	background: var(--tj-white);
}
.light-mode .hero-image-box img {
	border: 2px solid var(--tj-off-white);
}
.light-mode .hero-image-box img:hover {
	border: 2px solid var(--tj-theme-primary);
}
.light-mode .tj-header-area .header-info-list ul li a {
	color: var(--tj-theme-secondary);
}
.light-mode.inner-page .tj-header-area .header-info-list ul li a {
	color: var(--tj-white);
}
.light-mode.inner-page .tj-header-area.header-sticky .header-info-list ul li a {
	color: var(--tj-theme-secondary);
}
.light-mode.inner-page
	.tj-header-area.header-sticky
	.header-info-list
	ul
	li
	a:hover {
	color: var(--tj-theme-primary);
}
.light-mode .tj-header-area .header-info-list ul li a:hover {
	color: var(--tj-theme-primary);
}
.light-mode .hero-sub-title {
	color: var(--tj-theme-secondary);
}
.light-mode .hero-title,
.light-mode .about-area .about-left-content .title {
	background: var(--tj-theme-primary);
	background: -webkit-gradient(
		linear,
		left top,
		right top,
		from(var(--tj-theme-primary)),
		to(var(--tj-theme-secondary))
	);
	background: -o-linear-gradient(
		left,
		var(--tj-theme-primary) 0%,
		var(--tj-theme-secondary) 100%
	);
	background: linear-gradient(
		to right,
		var(--tj-theme-primary) 0%,
		var(--tj-theme-secondary) 100%
	);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
.light-mode .tj-header-area .header-menu ul li a {
	color: var(--tj-theme-secondary);
}
.light-mode.inner-page .tj-header-area .header-menu ul li a {
	color: var(--tj-white);
}
.light-mode.inner-page .tj-header-area.header-sticky .header-menu ul li a {
	color: var(--tj-theme-secondary);
}
.light-mode .hero-section .intro_text svg text.animate-stroke {
	opacity: 0.2;
}
.light-mode .hero-section .intro_text svg text {
	stroke: var(--tj-theme-primary);
}
.light-mode .funfact-item {
	color: var(--tj-theme-primary);
}
/* END: Hero Section */

/*
* ===============================
*  START: Services Section
* ===============================
*/
.light-mode .services-section {
	background-color: var(--tj-off-white);
}
.light-mode .services-widget .service-item {
	border-bottom: 1px solid var(--tj-grey-3);
}
.light-mode .services-widget .service-item .left-box .service-title {
	transition: all 0.5s ease;
	color: var(--tj-theme-primary);
}
.light-mode .services-widget .service-item.current .left-box .service-title {
	color: var(--tj-white);
}
.light-mode
	.services-widget
	.service-item.current.mleave
	.left-box
	.service-title {
	color: var(--tj-theme-primary);
}
.light-mode .services-widget .service-item:hover .left-box .service-title {
	color: var(--tj-white);
}
.light-mode .services-widget .service-item .right-box p {
	transition: all 0.5s ease;
	color: var(--tj-theme-secondary);
}
.light-mode .services-widget .service-item.current .right-box p {
	color: var(--tj-white);
}
.light-mode .services-widget .service-item.current.mleave .right-box p {
	color: var(--tj-theme-secondary);
}
.light-mode .services-widget .service-item:hover .right-box p {
	color: var(--tj-white);
}
/* END: Services Section */

/*
* ===============================
*  START: Portfolio Section
* ===============================
*/
.light-mode .portfolio-section {
	background: var(--tj-white);
}
.light-mode .portfolio-box .portfolio-item .content-box .portfolio-title {
	color: var(--tj-white);
}
.light-mode .portfolio-filter .button-group {
	background-color: var(--tj-off-white);
}
.light-mode .portfolio-filter .button-group button {
	color: var(--tj-theme-primary);
}
.light-mode .portfolio-filter .button-group button.active {
	color: var(--tj-white);
}
.light-mode .portfolio-box .portfolio-item .content-box p {
	color: var(--tj-white);
}
/*  END: Portfolio Section */

/*
* ===============================
*  START: Resume Section
* ===============================
*/
.light-mode .resume-section {
	background: var(--tj-off-white);
}
.light-mode .resume-widget .resume-item {
	background: var(--tj-white);
}
.light-mode .resume-widget .resume-item:hover {
	color: var(--tj-white);
}
.light-mode .resume-widget .resume-item .resume-title {
	color: var(--tj-theme-secondary);
}
.light-mode .resume-widget .resume-item:hover .resume-title {
	color: var(--tj-white);
}
/* END: Resume Section */

/*
* ===============================
*  START: Skills Section
* ===============================
*/
.light-mode .skills-section {
	background: var(--tj-white);
}
.light-mode .skills-widget .skill-inner {
	background: var(--tj-off-white);
}
/*  END: Skills Section */

/*
* ===============================
*  START: Testimonials Section
* ===============================
*/
.light-mode .testimonial-section {
	background: var(--tj-off-white);
}
.light-mode .testimonials-widget .testimonial-item {
	background: var(--tj-white);
}
.light-mode .testimonials-widget .testimonial-item .name {
	color: var(--tj-theme-secondary);
}
/* END: Testimonials Section */

/*
* ===============================
*  START: Blog Section
* ===============================
*/
.light-mode .blog-section {
	background: var(--tj-white);
}
.light-mode .blog-item .blog-content {
	background: var(--tj-white);
}
.light-mode .blog-item .blog-content .blog-title {
	color: var(--tj-theme-secondary);
}
.light-mode .blog-item:hover .blog-content .blog-title {
	color: var(--tj-white);
}
/* END: Blog Section */

/*
* ===============================
*  START: Contact Section
* ===============================
*/
.light-mode .contact-section {
	background: var(--tj-off-white);
}
.light-mode .contact-form-box {
	background: var(--tj-white);
}
.light-mode .contact-form-box .error {
	color: red;
}
.light-mode .form_group select,
.light-mode .form_group .nice-select,
.light-mode
	.form_group
	input:not([type="submit"]):not([type="radio"]):not([type="checkbox"]),
.light-mode .form_group textarea {
	background: var(--tj-off-white);
	border: 1px solid var(--tj-theme-secondary);
	color: var(--tj-theme-secondary);
	text-align: -webkit-right;
}
.light-mode .form_group input::-webkit-input-placeholder,
.light-mode .form_group textarea::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	color: var(--tj-grey-1);
}
.light-mode .form_group input::-moz-placeholder,
.light-mode .form_group textarea::-moz-placeholder {
	/* Chrome/Opera/Safari */
	color: var(--tj-grey-1);
}
.light-mode .form_group input:-ms-input-placeholder,
.light-mode .form_group textarea:-ms-input-placeholder {
	/* Chrome/Opera/Safari */
	color: var(--tj-grey-1);
}
.light-mode .form_group input:-moz-placeholder,
.light-mode .form_group textarea:-moz-placeholder {
	/* Chrome/Opera/Safari */
	color: var(--tj-grey-1);
}
.light-mode .contact-info-list .icon-box i {
	color: var(--tj-white);
}
.light-mode .contact-info-list .text-box a {
	color: var(--tj-theme-secondary);
}
.light-mode .contact-info-list .text-box a:hover {
	color: var(--tj-theme-primary);
}
.light-mode .tj-nice-select::after {
	background: url(../img/icons/down-arrow-light.svg);
	background-position: center;
	background-size: cover;
}
/* END: Contact Section */

/*
* ===============================
*  START: Footer Area
* ===============================
*/
.light-mode .tj-footer-area {
	background: var(--tj-theme-secondary);
}
/* END: Footer Area */

/*
* ===============================
*  START: Blog Standard Page
* ===============================
*/
.light-mode .tj-posts__area {
	background-color: var(--tj-off-white);
}
.light-mode .tj__pagination ul li .page-numbers {
	background-color: var(--tj-theme-secondary);
}
.light-mode .tj__pagination ul li .page-numbers:hover,
.light-mode .tj__pagination ul li .page-numbers.current {
	border-color: var(--tj-theme-primary);
	background-color: var(--tj-theme-primary);
}

/*
* ===============================
*  START: Blog Details Page
* ===============================
*/
.light-mode .tj-post-details__area {
	background-color: var(--tj-off-white);
}
.light-mode .tj-post__meta span {
	color: var(--tj-theme-primary);
}
.light-mode .tj-post__content .tj-post__title {
	color: var(--tj-theme-primary);
}
.light-mode .single-post_tag_share .share_link a {
	color: var(--tj-theme-primary);
}
.light-mode .single-post_tag_share .share_link a:hover {
	color: var(--tj-white);
}
.light-mode
	.single-post__navigation
	.tj-navigation_post
	.tj-navigation-post_inner
	.tj-content
	.post_pagination_title
	.title {
	color: var(--tj-white);
}
.light-mode
	.single-post__navigation
	.tj-navigation_post
	.tj-navigation-post_inner
	.tj-content
	.post_pagination_title
	.title:hover {
	color: var(--tj-theme-primary);
}
/* END: Blog Details Page */

/*
* ===============================
*  START: Breadcrumb Area
* ===============================
*/
.light-mode .breadcrumb_navigation i {
	color: var(--tj-white);
}
/* END: Breadcrumb Area */

/* Tablet Devices */
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.light-mode .tj-header-area .header-menu ul li a {
		color: var(--tj-white);
	}
}

/* Mobile Devices */
@media only screen and (max-width: 767px) {
	.light-mode .tj-header-area .header-menu ul li a {
		color: var(--tj-white);
	}
}

/*
* ===============================
*  START: Home 2
* ===============================
*/
.light-mode .contact-content-area,
.light-mode .works-content-area,
.light-mode .specialization-item,
.light-mode .project-content-area,
.light-mode .skills-widget.style-2 .skill-item .skill-inner,
.light-mode .hero-wrrapper,
.light-mode .funfact-area.style-2,
.light-mode .about-area .about-left-content,
.light-mode .about-area .about-right-content {
	background-color: var(--tj-off-white);
	border-color: var(--tj-grey-2);
}
.project-feature-item .project-text .title,
.light-mode .about-area .about-right-content .about-text .title {
	color: var(--tj-theme-primary);
}
.light-mode .contact-info-list-3 li > a,
.light-mode .contact-info-list-3 li > p,
.light-mode .works-item .sub-title,
.light-mode .specialization-item .sub-title,
.light-mode .specialization-item .specialization-content .desc,
.light-mode .project-left-content .project_content .desc,
.light-mode .about-area .about-right-content .about-text .desc,
.light-mode .about-area .about-left-content .desc {
	color: var(--tj-theme-accent-2);
}
.light-mode .skills-widget.style-2 .skill-item:hover .skill-inner {
	border-color: var(--tj-theme-primary);
	background-color: var(--tj-theme-secondary);
}
.light-mode .contact-info-list-3 li > a:hover,
.light-mode .skills-widget.style-2 .skill-item p {
	color: var(--tj-theme-primary);
}
.light-mode .contact-section.style-2,
.light-mode .brand-section,
.light-mode .testimonial-section.style-2,
.light-mode .works-section,
.light-mode .specialization-section,
.light-mode .project-section {
	background-color: var(--tj-white);
}
.light-mode .contact-content-area .contact-form-box,
.light-mode .specialization-item {
	background: var(--tj-off-white);
}
.light-mode .specialization-item:hover {
	border-color: var(--tj-theme-primary);
}
.light-mode .works-item .title {
	color: var(--tj-theme-secondary);
}
.light-mode .works-content-area .works-content-item .works-content-box::before {
	background-color: var(--tj-grey-2);
}
.light-mode .specialization-item .specialization-icon {
	background-color: var(--tj-theme-primary-2);
}
